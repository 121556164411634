import React from 'react';
// import Link from '../utils/link';
// import layoutQuery from '../hooks/use-layout-query';
// import { And, Wletter } from '../components/icons';

function Footer() {

  return (
    <>
      {/* <footer className='footer'>
      <div className='footer__inner'>
          <div className='col'>
            <div className='and'>
              <And color='#312012' />
            </div>
          </div>
        </div>
      </footer>

      <div className='footer-right '>
        <div className='Wletter'>
          <Wletter color='#312012' />
        </div>
      </div> */}
    </>
  );
}

export default Footer;
