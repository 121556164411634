import React from 'react';
// import { Link, useStaticQuery, graphql } from 'gatsby';
import { Logo, TagLine } from '../components/icons';


const Header = () => {
  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div className='header__top'>
            <TagLine />
          </div>

          <div className='header__center'>
            <div className='logo'>
              <Logo color='#fff' />
            </div>
          </div>
        </div>
      </header>

      {/* <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`} style={{ backgroundColor: showProjectsMenu ? '#BFA487' : (showStoriesMenu ? '#AA9D92' : '#EEE9E2') }}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              <li></li>
            </ul>
          </nav>
        </div>
      </div> */}
    </>
  );
};

export default Header;
